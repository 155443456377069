import { Component } from "react";
import "../extracss/loader.css";

class SpinnerLoader extends Component {
	constructor() {
		super();
		this.divStyle = {
			height: 100 + `vh`,
			width: 100 + `%`,
			text: `center`,
			backgroundColor: `#222`,
			textAlign: `center`,
		};
		this.pStyle = {
			color: `white`,
			letterSpacing: 0.2 + `rem`,
			textAlign: `center`,
		};
		this.holderStyle = {
			width: 100 + `%`,
			marginLeft: `auto`,
			marginRight: `auto`,
			position: `absolute`,
			top: `50%`,
			transform: `translateY(-50%)`,
		};
		this.messages = {
			loadingMessage: `KRAUNAMA`,
		};
	}

	render() {
		return (
			<div style={this.divStyle}>
				<div style={this.holderStyle}>
					<div>
						<div className="loader"></div>
					</div>
					<div>
						<p style={this.pStyle}>{this.messages.loadingMessage}</p>
					</div>
				</div>
			</div>
		);
	}
}

export default SpinnerLoader;
