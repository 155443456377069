import { React, Component } from "react";
import {
  AmpStoryGridLayer,
  AmpStory,
  AmpStoryPage,
  AmpImg,
  AmpHeader,
} from "react-google-stories";
import SpinnerLoader from "./loader";

class StoriesComponent extends Component {
  constructor() {
    super();
    this.fetchLink = `https://postthread.artefaktas.eu/`;
    // this.instaLink = `https://media.artefaktas.workers.dev`;
    this.wpLink = `https://wordpressapi.artefaktas.eu/api`;
    this.nerdyLink = `https://nerdyapi.artefaktas.eu/api`;
    this.creativeLink = `https://creativeapi.artefaktas.eu/api`;
    this.state = {
      data: null,
      instadata: null,
      wpdata: null,
      nerdyData: null,
      creativeData: null,
    };
    this.messages = {
      readMore: `Skaityti`,
      visitInsragram: `Instagram`,
      instagramNotitle: `Autorius nepridėjo pavadinimo, bet gražu... Nepasiginčysi`,
    };
    this.ampStyle = {
      width: 100 + `%`,
      height: 100 + `vh`,
      color: `white`,
    };
    this.defaultImage = `../images/animal-world-attention-branch-267074.jpg`;
    this.extras = {
      title: `Artefaktas.eu stories`,
      publisher: `Artefaktas Blog`,
      logoLink: `../images/128.png`,
    };
  }

  componentDidMount() {
    let link = this.fetchLink;
    let wpLink = this.wpLink;
    let nerdLink = this.nerdyLink;
    let createLink = this.creativeLink;
    let params = {
      headers: { "User-Agent": "artefaktasbot" },
    };
    async function fetchData(passedParams) {
      let res = await fetch(link, passedParams);
      let js = await res.json();
      return js.items;
    }
    // async function getInstaData() {
    //   let res = await fetch(instaLink);
    //   let js = await res.json();
    //   return js.data;
    // }
    async function getWpData(passedParams) {
      let res = await fetch(wpLink, passedParams);
      let js = await res.json();
      return js;
    }
    async function getNerdyData(passedParams) {
      let res = await fetch(nerdLink, passedParams);
      let js = await res.json();
      return js;
    }

    async function getCreativeData(passedParams) {
      let res = await fetch(createLink, passedParams);
      let js = await res.json();
      return js;
    }

    fetchData(params).then((r) => {
      this.setState({ data: r });
    });
    // getInstaData().then((d) => {
    //   this.setState({ instadata: d });
    // });
    getWpData(params).then((wp) => {
      this.setState({ wpdata: wp });
    });
    getNerdyData(params).then((bd) => {
      this.setState({ nerdyData: bd });
    });

    getCreativeData(params).then((bd) => {
      this.setState({ creativeData: bd });
    });
  }

  // generateInstagramStories(passedArray) {
  //   return passedArray.map((r, i) => {
  //     return (
  //       <AmpStoryPage id={i} key={i}>
  //         <AmpStoryGridLayer template="fill">
  //           {r.media_type === "IMAGE" ? (
  //             <AmpImg
  //               src={r.media_url}
  //               height="720"
  //               width="1280"
  //               layout="responsive"
  //             />
  //           ) : r.media_type === "CAROUSEL_ALBUM" ? (
  //             <AmpImg
  //               src={r.media_url}
  //               height="720"
  //               width="1280"
  //               layout="responsive"
  //             />
  //           ) : r.media_type === "VIDEO" ? (
  //             <amp-video
  //               autoplay
  //               loop
  //               grid-area="middle-third"
  //               width="1280"
  //               height="720"
  //               poster={r.thumbnail_url}
  //               layout="responsive"
  //               alt="artefaktasampvideo"
  //             >
  //               <source src={r.media_url} type="video/mp4" />
  //             </amp-video>
  //           ) : (
  //             ""
  //           )}
  //         </AmpStoryGridLayer>
  //         <AmpStoryGridLayer template="vertical">
  //           <AmpHeader
  //             level={3}
  //             title={
  //               r.caption
  //                 ? String(r.caption.split("\n")[0])
  //                     .replaceAll(":", "")
  //                     .replaceAll("(", "")
  //                     .replaceAll(")", "")
  //                     .replaceAll("#", "")
  //                     .replaceAll("+", "")
  //                     .replaceAll("-", "")
  //                 : this.messages.instagramNotitle
  //             }
  //           />
  //         </AmpStoryGridLayer>
  //         {i !== 0 ? (
  //           <amp-story-cta-layer>
  //             <a href={r.permalink}>{this.messages.visitInsragram}</a>
  //           </amp-story-cta-layer>
  //         ) : (
  //           ""
  //         )}
  //       </AmpStoryPage>
  //     );
  //   });
  // }

  generateBlogStories(passedArray) {
    return passedArray.map((d, i) => {
      return (
        <AmpStoryPage id={d.id} key={d.id}>
          <AmpStoryGridLayer template="fill">
            <AmpImg
              src={d.images ? d.images[0].url : this.defaultImage}
              height="720"
              width="1280"
              layout="responsive"
            />
          </AmpStoryGridLayer>
          <AmpStoryGridLayer template="vertical">
            <AmpHeader
              level={3}
              title={String(d.title)
                .replaceAll(":", "")
                .replaceAll("(", "")
                .replaceAll(")", "")
                .replaceAll("+", "")
                .replaceAll("-", "")}
            />
          </AmpStoryGridLayer>
          <amp-story-cta-layer>
            <a href={d.url}>{this.messages.readMore}</a>
          </amp-story-cta-layer>
        </AmpStoryPage>
      );
    });
  }

  generateNerdyStories(passedArray) {
    return passedArray.map((bdpost, bp) => {
      {
        return (
          <AmpStoryPage id={`nd` + bp} key={bp}>
            <AmpStoryGridLayer template="fill">
              <AmpImg
                src={bdpost.coverImage ? bdpost.coverImage : this.defaultImage}
                height="720"
                width="1280"
                layout="responsive"
              />
            </AmpStoryGridLayer>
            <AmpStoryGridLayer template="vertical">
              <AmpHeader
                level={3}
                title={String(bdpost.title)
                  .replaceAll(":", "")
                  .replaceAll("(", "")
                  .replaceAll(")", "")
                  .replaceAll("+", "")
                  .replaceAll("-", "")}
              />
            </AmpStoryGridLayer>
            <amp-story-cta-layer>
              <a href={bdpost.href}>{this.messages.readMore}</a>
            </amp-story-cta-layer>
          </AmpStoryPage>
        );
      }
    });
  }

  generateCreativeStories(passedArray) {
    return passedArray.map((bdpost, bp) => {
      {
        return (
          <AmpStoryPage id={`cr` + bp} key={bp}>
            <AmpStoryGridLayer template="fill">
              <AmpImg
                src={bdpost.coverImage ? bdpost.coverImage : this.defaultImage}
                height="720"
                width="1280"
                layout="responsive"
              />
            </AmpStoryGridLayer>
            <AmpStoryGridLayer template="vertical">
              <AmpHeader
                level={3}
                title={String(bdpost.title)
                  .replaceAll(":", "")
                  .replaceAll("(", "")
                  .replaceAll(")", "")
                  .replaceAll("+", "")
                  .replaceAll("-", "")}
              />
            </AmpStoryGridLayer>
            <amp-story-cta-layer>
              <a href={bdpost.href}>{this.messages.readMore}</a>
            </amp-story-cta-layer>
          </AmpStoryPage>
        );
      }
    });
  }

  generateWordpressStories(passedArray) {
    return passedArray.map((wppost, wi) => {
      return (
        <AmpStoryPage id={`wp` + wi} key={wi}>
          <AmpStoryGridLayer template="fill">
            <AmpImg
              src={
                wppost.jetpack_featured_media_url
                  ? wppost.jetpack_featured_media_url
                  : this.defaultImage
              }
              height="720"
              width="1280"
              layout="responsive"
            />
          </AmpStoryGridLayer>
          <AmpStoryGridLayer template="vertical">
            <AmpHeader
              level={3}
              title={String(wppost.title.rendered)
                .replaceAll(":", "")
                .replaceAll("(", "")
                .replaceAll(")", "")
                .replaceAll("+", "")
                .replaceAll("-", "")}
            />
          </AmpStoryGridLayer>
          <amp-story-cta-layer>
            <a href={wppost.link}>{this.messages.readMore}</a>
          </amp-story-cta-layer>
        </AmpStoryPage>
      );
    });
  }

  render() {
    return (
      <div>
        {this.state.data !== null &&
        // this.state.instadata !== null &&
        this.state.wpdata !== null ? (
          <AmpStory
            title={this.extras.title}
            publisher={this.extras.publisher}
            publisher-logo-src={this.extras.logoLink}
            style={this.ampStyle}
          >
            {this.generateBlogStories(this.state.data)}
            {this.state.nerdyData !== null &&
            this.state.nerdyData.length &&
            this.state.nerdyData !== ""
              ? this.generateNerdyStories(this.state.nerdyData)
              : console.log("no nerdy data")}
            {this.state.creativeData !== null &&
            this.state.creativeData.length &&
            this.state.creativeData !== ""
              ? this.generateCreativeStories(this.state.creativeData)
              : console.log("no creative data")}
            {this.state.wpdata !== null &&
            this.state.wpdata.length &&
            this.state.wpdata !== ""
              ? this.generateWordpressStories(this.state.wpdata)
              : console.log("no wp data")}
          </AmpStory>
        ) : (
          <SpinnerLoader />
        )}
      </div>
    );
  }
}

export default StoriesComponent;
